import React, { ButtonHTMLAttributes } from "react";
import $ from "jquery";

export interface CommonAlertPopupProps {
  title?: string; // 제목
  message: string; // 메세지
  buttons?: Array<
    {
      type: boolean; // true: 확인, false: 취소
      label: string; // 버튼명
    } & ButtonHTMLAttributes<HTMLButtonElement>
  >;
  open?: boolean; // popup open 여부
}

/**
 * 공통 ALERT 팝업
 * @param props
 * @returns
 */
const CommonAlertPopup = (props: CommonAlertPopupProps) => {
  let prev_scr_pos = 0; //body 스크롤 잠궜을때 스크롤 높이(ios대응으로 필요)

  // scroll 잠금
  const body_scr_lock = () => {
    prev_scr_pos = $(window).scrollTop();
    $("body").css("top", -prev_scr_pos + "px");
    $("body").addClass("__lock_scroll");
  };

  // scroll 잠금해제
  const body_scr_unlock = () => {
    $("body").removeClass("__lock_scroll");
    $(window).scrollTop(prev_scr_pos);
  };

  // 알림창 open
  // const fnPopupOpen = () => {
  if (props.open) {
    if (!$("body").hasClass("__lock_scroll")) {
      body_scr_lock();
    }
    if (!$(".alert_open_btn").hasClass("__open")) {
      $(".alert_wrap").addClass("__open");
    }
  }

  // 알림창 close
  const fnPopupClose = () => {
    $(".alert_wrap").removeClass("__open");
    body_scr_unlock();
  };

  const popData = () => {
    if (props.open) {
      $("#message").html(props.message);

      if (props.title) {
        $("#title").html(props.title);
      } else {
        $("#title").html("");
      }

      if (props.buttons?.length) {
        $("#cancle").hide();

        props.buttons.map((button, index) => {
          if (button.type) {
            $("#confirm").html(button.label);
            $("#confirm")
              .off()
              .on("click", function () {
                $("#message").html("");
                fnPopupClose();
                if (button.onClick) {
                  button.onClick();
                }
              });
          } else {
            $("#cancle").show();
            $("#cancle").html(button.label);
            $("#cancle")
              .off()
              .on("click", function () {
                $("#message").html("");
                fnPopupClose();
                if (button.onClick) {
                  button.onClick();
                }
              });
          }
        });
      }
    }
  };

  return (
    <>
      <div className="alert_wrap">
        <div className="alert_cont_wrap">
          <h5 className="alert_tit" id="title">
            &nbsp;
          </h5>
          <div className="alert_cont">
            <p className="alert_txt" id="message"></p>
            <div className="board_btns comm_style">
              <button type="button" className="sub_comm_btn bg_white size_ms" title="" id="cancle" onClick={() => fnPopupClose(true)}>
                취소
              </button>
              <button type="button" className="sub_comm_btn bg_blue size_ms" title="" id="confirm" onClick={() => fnPopupClose(false)}>
                확인
              </button>
            </div>
          </div>
          <button type="button" className="alert_close_btn" title="얼럿 닫기" onClick={() => fnPopupClose(false)}>
            얼럿 창 닫기
          </button>
        </div>
      </div>
      {popData()}
    </>
  );
};

export default CommonAlertPopup;
