import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";

const App = () => {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
  // Containers
  const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
  const KakaoAuthHandler = React.lazy(() => import("./utils/KakaoAuthHandler"));
  const MobileReturnLayout = React.lazy(() => import("./utils/MobilePaymentReturn"));

  useEffect(() => {}, []);
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/CP02010T/processKakaoLogin" element={<KakaoAuthHandler />} />
            <Route exact path="/paymentReturn" element={<MobileReturnLayout />}/>
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      {/* <ToastContainer /> */}
    </>
  );
};

export default App;
